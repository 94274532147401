import React from 'react'

function PageNotFound() {
  return (
    <div className='bg-danger vh-100 d-flex justify-content-center align-items-center'>
        <div><h1>404 Page not found</h1></div>
    </div>
  )
}

export default PageNotFound