import React from 'react'

function MyDocuments() {
  return (
    <div className='vh-100 bg-dark d-flex justify-content-center align-items-center'>
        <h1 className='bg-warning p-2'>Work in progress</h1>
    </div>
  )
}

export default MyDocuments